import React, { useEffect, useState } from 'react'
import {  useSelector } from 'react-redux'
import { deleteWalkInDriveByRecruiter, fetchWalkInDrivesByRecruiter } from '../../../../services/operations/JobAPI';
import Spinner from '../../../common/Spinner';
import { formattedFullDate } from '../../../../utils/dateFormatter';
import { Link } from 'react-router-dom';
import { fetchBlogsByRecruiter } from '../../../../services/operations/BlogAPI';

const MyBlogs = () => {
    
    const {token} = useSelector(state => state.auth);
    const [blogs,setBlogs] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        const fetchBlogs = async()=>{
            setLoading(true);
            const result = await fetchBlogsByRecruiter(token);
            if(result){
                setBlogs(result);
            }
            setLoading(false);
        }
        fetchBlogs();
    },[])

    const deleteWalkInDrive = async(walkInId)=>{
        // const result = await deleteWalkInDriveByRecruiter({walkInId},token);
        // setBlogs(prev => prev?.filter(wd => wd?._id !== walkInId));
    }

    if(loading || !blogs)
        return <Spinner/>
    
    if(!loading && !blogs)
        return <p>500 Internal Server Error</p>

    return (
    <div className='w-11/12 max-w-maxContent mx-auto'>
        <p className='p-2 my-4 text-4xl font-semibold'>My Blogs</p>
            {
                blogs && blogs.length 
                
                ? <section className="flex flex-col items-stretch my-10 ">
                    {/* List Header */}
                    <div className="hidden w-full h-[30px] xslg:grid grid-cols-[3fr_2fr_2fr_2fr] place-content-center  gap-6 p-3 py-6 border border-richblack-700 text-yellow-50">
                        <p className="text-start px-2">Title</p>
                        <p className="px-2">Category</p>
                        <p className="px-2">Created On</p>
                        <p className="px-2 place-self-center">Actions</p>
                    </div>

                    {/* List Items */}
                    {blogs.map((bg, index) => {
                        return (
                            <div
                                key={index}
                                className="w-full grid xslg:grid-cols-[3fr_2fr_2fr_2fr]  gap-4 p-3 border border-richblack-700 "
                            >
                                <div className="w-full my-auto text-richblack-100 px-2">
                                    {bg?.title}
                                </div>
                                <div className="w-full my-auto text-richblack-100 px-2">
                                    {bg?.category?.name}
                                </div>
                                <div className="w-full my-auto text-richblack-100 px-2">
                                    <p>{formattedFullDate(bg?.createdAt)}</p>
                                </div>

                                <div className="flex gap-x-2 items-center text-xs text-richblack-50 font-bold md:justify-evenly p-2">
                                    <Link
                                        to={`/dashboard/recruiter/edit-blog/${bg?._id}`} 
                                        className="bg-caribbeangreen-300 hover:bg-caribbeangreen-400 transition-all duration-100 px-4 py-2 rounded-lg flex-grow text-center"
                                    >
                                        <button>Edit</button>
                                    </Link>
                                    
                                    {/* <button 
                                        className="bg-pink-600 hover:bg-pink-700 transition-all duration-100 px-4 py-2 rounded-lg flex-grow"
                                        onClick={()=>{deleteWalkInDrive(bg?._id)}}
                                    >   
                                        Delete
                                    </button> */}
                                    
                                </div>
                            </div>
                        );
                    })}
                </section>
                
                : <p>No Blogs Posted Yet</p>
            }
    </div>
  )
}

export default MyBlogs