import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import jobReducer from "../slices/jobSlice";
import walkInReducer from "../slices/walkInDriveSlice";
import categoryReducer from "../slices/CategorySlice";
import cartReducer from "../slices/cartSlice";
import courseReducer from "../slices/courseSlice"
import viewCourseReducer from "../slices/viewCourseSlice"

const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    job:jobReducer,
    walkInDrive:walkInReducer,
    category:categoryReducer,
    cart: cartReducer,
    course: courseReducer,
    viewCourse: viewCourseReducer
});


export default rootReducer;