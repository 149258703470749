import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { fetchBlogDetails } from '../services/operations/BlogAPI';

const BlogDetail = () => {
  
  const {blogId} = useParams();
  // const location = useLocation();
  // const blogId = location.state?.blogId;

  const [blog,setBlog] = useState({});
  
  useEffect(()=>{
    const setBlogDetails = async () => {
      const data = await fetchBlogDetails(blogId);
      if(data){
        setBlog(data)
      }
    }
    setBlogDetails();
  },[])

  // console.log(blog)

  return (
    <div className='w-11/12 max-w-maxContent mx-auto py-10 flex flex-col gap-y-4'>
      <p className='text-4xl font-bold'>{blog?.title}</p>
      <div className='w-full'>
        <img src={blog?.featuredImage} className='w-full object-contain'/>
      </div>
      <p>{blog?.subtitle}</p>
    </div>
  )
}

export default BlogDetail