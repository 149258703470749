import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
// import { deleteSection, deleteSubSection } from '../../../../../services/operations/CourseAPI';
import { setCourse } from '../../../../../../slices/courseSlice';
import ConfirmationModal from '../../../../../common/ConfirmationModal';
import { deleteSection } from '../../../../../../services/operations/BlogAPI';


export const NestedView = ({setEditSection,setViewSection}) => {

    const dispatch = useDispatch();
    const {course} = useSelector(state => state.course);
    const {token} = useSelector(state => state.auth);

    const [loading,setLoading] = useState(false); 
    const [confirmationModal, setConfirmationModal] = useState(null);
    const handleDeleteSection = async(sectionId)=>{
        
        setLoading(true);
        let result = await deleteSection({
            sectionId:sectionId,
            blogId:course._id
        },token);
        
        if(result){
            dispatch(setCourse(result));
        }
        setLoading(false);
    }

  return (
    <div className='bg-richblack-700 flex flex-col gap-y-3 text-richblack-5 p-5 px-3 xxs:px-7 my-5 rounded-lg'>
          {
            course?.sections?.map((section)=>(
                <div
                    key={section._id} 
                    className='flex max-xxs:flex-col gap-y-2 xxs:items-center justify-between mt-2 py-3 text-richblack-400 border-b border-richblack-500'
                >
            
                    <p className='font-bold text-richblack-50'>
                        {section?.heading ? section?.heading?.trim().slice(0,10) : "No Title"}
                    </p>
                    <div className=' max-xxs:my-1 flex self-end items-center gap-x-2 text-2xl'>
                        {/* Edit button */}
                        <button
                            type='button'
                            onClick={()=>{setEditSection(section)}}
                        >
                        <MdEdit />
                        </button>

                        {/* Delete button */}
                        <button
                            type='button'
                            onClick={()=>
                                setConfirmationModal({
                                    text1:"Delete this Section?",
                                    text2:"This section will be deleted",
                                    btnText1:"Delete",
                                    btnText2:"Cancel",
                                    btnHandler1:()=>{
                                        handleDeleteSection(section._id);
                                        setConfirmationModal(null);
                                    },
                                    btnHandler2:()=>{
                                        setConfirmationModal(null);
                                    }
                                })
                            }
                            disabled={loading}
                        >
                            <RiDeleteBin5Line />
                        </button>

                    </div>   
                </div>
            ))
          }

          {/* Display Confirmation Modal for delete subSection */}
          {
            confirmationModal &&
            <ConfirmationModal modalData={confirmationModal}/>
          }
    </div>
  )
}
