import { apiConnector } from "../apiconnector";
import toast from "react-hot-toast";
import { blogEndPoints } from "../apis";

const {
    CREATE_BLOG_API,
    EDIT_BLOG_API,
    CREATE_SECTION_API,
    EDIT_SECTION_API,
    DELETE_SECTION_API,
    GET_BLOGS_BY_RECRUITER_API,
    GET_ALL_BLOGS_API,
    GET_BLOG_DETAILS_API
} = blogEndPoints


export async function createBlog(formData,token){
    let result = null;
    const toastId = toast.loading("Saving Blog Details");
    try{

        // console.log("here: , formData: ",formData);
        const response = await apiConnector(
            "POST",
            CREATE_BLOG_API,
            formData,
            {
                "Content-Type": "multipart/form-data",
                Authorization:`Bearer ${token}`
            }
        );

        if(!response?.data?.success)
            throw new Error(response?.data?.message);
        
        toast.success(response?.data?.message);
        // console.log("CREATE BLOG API REPONSE....",response);
        result = {
            result: response?.data?.data,
            updatedUser: response?.data?.updatedUser
        }

    }catch(err){
        toast.error(err?.response?.data?.message || err?.message);
        // console.log("CREATE BLOG API RESPONSE....",err);
    }
    toast.dismiss(toastId);
    return result;
}

export async function editBlog(formData,token){
    let result = null;
    const toastId = toast.loading("Saving Blog Details");
    try{

        // console.log(Object.fromEntries(formData))
            const response = await apiConnector(
                "POST",
                EDIT_BLOG_API,
                formData,
                {
                    "Content-Type": "multipart/form-data",
                    Authorization:`Bearer ${token}`
                }
            );

            if(!response?.data?.success)
                throw new Error(response?.data?.message);
            
            toast.success(response?.data?.message);
            // console.log("UPDATE BLOG API REPONSE....",response);
            result = response?.data?.data;

    }catch(err){
        toast.error(err?.response?.data?.message || err?.message);
        // console.log("UPDATE BLOG API ERROR....",err);
    }
    toast.dismiss(toastId);
    return result;
}

export async function createSection(formData,token){
    let result;
    const toastId = toast.loading("Creating Section");

    try{
        // console.log("data:",formData,"token:",token);
        const response = await apiConnector(
            "POST",
            CREATE_SECTION_API,
            formData,
            {
                Authorization:`Bearer ${token}`
            }
        );
        
        if(!response?.data?.success)
            throw new Error(response?.data?.message);
        
        // console.log("CREATE SECTION API RESPONSE...." ,response);
        toast.dismiss(toastId);
        toast.success(response?.data?.message);
        result = response?.data?.data;

    }catch(err){
        toast.dismiss(toastId);
        // console.log("CREATE SECTION API RESPONSE...." ,err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    return result;
}

export async function updateSection(formData,token){
    let result;
    const toastId = toast.loading("Updating Section");

    try{
        // console.log("data:",formData,"token:",token);
        const response = await apiConnector(
            "POST",
            EDIT_SECTION_API,
            formData,
            {
                Authorization:`Bearer ${token}`
            }
        );
        
        if(!response.data.success)
            throw new Error(response.data.message);
        
        // console.log("UPDATE SECTION API RESPONSE...." ,response);
        toast.dismiss(toastId);
        toast.success(response?.data?.message);
        result = response.data.data;

    }catch(err){
        toast.dismiss(toastId);
        toast.error(err?.response?.data?.message || err?.message);
        // console.log("UPDATE SECTION API RESPONSE...." ,err);
    }
    return result;
}

export async function deleteSection(formData,token){
    let result;
    const toastId = toast.loading("Deleting Section");

    try{
        // console.log("data:",formData,"token:",token);
        const response = await apiConnector(
            "POST",
            DELETE_SECTION_API,
            formData,
            {
                Authorization:`Bearer ${token}`
            }
        );
        
        if(!response.data.success)
            throw new Error(response.data.message);
        
        // console.log("UPDATE SECTION API RESPONSE...." ,response);
        toast.dismiss(toastId);
        toast.success(response?.data?.message);
        result = response?.data?.data;

    }catch(err){
        toast.dismiss(toastId);
        toast.error(err?.response?.data?.message || err?.message);
        // console.log("UPDATE SECTION API RESPONSE...." ,err);
    }
    return result;
}

export async function fetchBlogsByRecruiter(token){
    let result = null;
    const toastId = toast.loading("Fetching Blogs");
    try{

        const response = await apiConnector(
            "GET",
            GET_BLOGS_BY_RECRUITER_API,
            null,
            {
                "Authorization":`Bearer ${token}`
            }
        );

        if(!response?.data?.success)
            throw new Error(response?.data?.message);
            
        // toast.success(response?.data?.message);
        // console.log("GET ALL BLOGS API RESPONSE...",response);
        result = response?.data?.data;

    }catch(err){
        // console.log("GET ALL BLOGS API ERROR....",err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    toast.dismiss(toastId);
    return result;
}

export async function fetchAllBlogs(){
    let result = null;
    const toastId = toast.loading("Fetching Blogs");
    try{

        const response = await apiConnector(
            "GET",
            GET_ALL_BLOGS_API,
        );

        if(!response?.data?.success)
            throw new Error(response?.data?.message);
            
        // toast.success(response?.data?.message);
        // console.log("GET ALL BLOGS API RESPONSE...",response);
        result = response?.data?.data;

    }catch(err){
        // console.log("GET ALL BLOGS API ERROR....",err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    toast.dismiss(toastId);
    return result;
}

export async function fetchBlogDetails(blogId){
    let result = null;
    const toastId = toast.loading("loading");
    try{

        const response = await apiConnector(
            "POST",
            GET_BLOG_DETAILS_API,
            {blogId}
        );
        
        if(!response?.data?.success)
            throw new Error(response?.data?.message);

        // toast.success(response?.data?.message);
        // console.log("GET BLOG DETAILS API RESPONSE....",response);
        result = response?.data?.data;

    }catch(err){
        // console.log("GET BLOG DETAILS API ERROR....",err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    toast.dismiss(toastId);
    return result;
}
