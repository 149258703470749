import React, { useEffect, useState } from 'react';
import { setCourse, setEditCourse, setStep } from '../../../../../slices/courseSlice';
import { RenderSteps } from './RenderSteps';
import { useDispatch, useSelector } from 'react-redux';

const CreateBlog = () => {

  const dispatch = useDispatch();
  const {editCourse,course} = useSelector(state => state.course);
  const [loading , setLoading] = useState(false);

  const setNull = ()=>{
    setLoading(true);
    dispatch(setEditCourse(false)); 
    dispatch(setCourse(null)); 
    dispatch(setStep(1));
    setLoading(false);
  }
  // console.log("loading: ",loading);
  // console.log("courseInfo: ",course,editCourse);

  useEffect(()=>{
    if(!editCourse){
      setNull();
    }
  },[]);

  return (
    <div className='w-11/12 mx-auto text-richblack-5 font-inter'>
        
        <div className=' flex gap-8 justify-center'>
          
          {/* Add Course Forms */}
          {
            !loading &&
            <RenderSteps/>
          }
          
          {/* Add Course Tips */}
            <div className='max-xls:hidden sticky top-0 max-w-[400px] h-fit bg-richblack-800 p-6 rounded-lg border border-richblack-700 mr-10'>
                <p className='font-bold text-lg'>⚡Blog Upload Tips</p>
                <ul className='flex flex-col gap-2 text-[14px] text-richblack-5 list-disc m-4 mr-0'>
                  <li>Write Engaging and Informative Blog Content.</li>
                  <li>Craft a Compelling Headline.</li>
                  <li>Standard size for the featured image is 1024x576.</li>
                  <li>Conduct Effective Keyword Research.</li>
                  <li>Use Visuals Effectively.</li>
                  <li>Check Social Media for Inspiration</li>
                </ul>
            </div>

        </div>

    </div>
  )
}

export default CreateBlog;