import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../common/Spinner';
import { AddJob } from './AddJob';
import { fetchBlogDetails } from '../../../../services/operations/BlogAPI';
import { setCourse, setEditCourse } from '../../../../slices/courseSlice';
import CreateBlog from './Add Course';

export const EditBlog = () => {

    const dispatch = useDispatch();

    const {blogId} = useParams();
    const [loading,setLoading] = useState(true);
    const {course} = useSelector(state => state.course);

    const setBlogData = async(blogId)=>{
        setLoading(true);
        let result = await fetchBlogDetails(blogId);
        if(result){
            result = {
                ...result,
                category: result?.category?._id
            }
            dispatch(setEditCourse(true));
            dispatch(setCourse(result));
        }
        setLoading(false);
    }
    // console.log("result: ",course);

    useEffect(()=>{
        setBlogData(blogId);
    },[]);

    if(loading)
        return <Spinner/>

  return (
    <div className='w-11/12 mx-auto text-richblack-5 font-inter'>
        {
            !loading && course &&
            <CreateBlog/>
        }
    </div>
  )
}
