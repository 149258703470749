import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { setCourse } from '../../../../../../slices/courseSlice';
import toast from 'react-hot-toast';
import IconBtn from '../../../../../common/IconBtn';
import Requirements from '../../../../../common/Requirements';
import { createSection, updateSection } from '../../../../../../services/operations/BlogAPI';

export const SubSectionModal = ({
    modalData,
    setModalData,
    add = false,
    edit = false,
    view = false
}) => {

    const dispatch = useDispatch();
    const {course} = useSelector(state => state.course);
    const {token} = useSelector(state => state.auth);

    const [loading , setLoading] = useState(null);

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState:{errors}
    } = useForm();
    
    useEffect(()=>{

        if(edit || view){
            setValue("heading",modalData?.heading);
            setValue("description",modalData?.description);
            setValue("links",modalData?.links);
        }

    },[]);

    // console.log("modalData:", modalData)

    const isFormUpdated = ()=>{
        const currentValues = getValues();
        if(
            currentValues?.heading !== modalData?.heading ||
            currentValues?.description !== modalData?.description ||
            currentValues?.links?.toString() !== modalData?.links?.toString()
        ) 
            return true;

        return false;
    }

    const editSection = async(data)=>{
        if(isFormUpdated()){
            let currentValues = getValues();
            const formData = new FormData();

            formData.append("sectionId",modalData?._id);

            if(currentValues?.heading !== modalData?.heading)
                formData?.append("heading",data?.heading);
            
            if(currentValues?.description !== modalData?.description)
                formData?.append("description",data?.description);

            if(currentValues.links?.toString() !== modalData.links?.toString()){
                // currentValues = {...currentValues, links: JSON.stringify(currentValues?.links)}; 
                formData.append("links",data?.links);
            }

            // console.log(formData.values())

            const result = await updateSection(formData,token);
            if(result){
                //update the updated section in the course
                const updatedSections = course?.sections.map(section => (
                    section?._id === modalData?._id ? result : section
                ));
                
                //set new value of course in state variable of course in slice
                // console.log(updatedSections)
                const updatedBlog = {...course, sections:updatedSections};
                dispatch(setCourse(updatedBlog));
                setModalData(null);
            }
        }
        else{
            toast.error("No Changes Made So Far");
        }
    }

    const onSubmit = async(data)=>{
        setLoading(true);

        // console.log("data",data);
        data = {
            ...data,
            links: JSON.stringify(data?.links ? data?.links : [])
        }

        
        if(!view){
            if(edit){
                editSection(data);
            }
            else{

                const formData = new FormData();
                formData.append("blogId",modalData);
                formData.append("heading",data?.heading);
                formData.append("description",data?.description);
                formData.append("links",data?.links);

                const result = await createSection(formData,token);

                if(result){
                    dispatch(setCourse(result));
                    setModalData(null);
                }
            }
        }

        setLoading(false);
    }

  return (
    <div className='absolute left-0 bottom-0 right-0 top-0 backdrop-blur-sm z-50 bg-black bg-opacity-10 text-richblack-5 overflow-scroll'>

        <div className='bg-richblack-800 rounded-lg border border-richblack-700 flex flex-col w-11/12 max-w-[700px] mx-auto my-6'>

            {/* Title */}
            <div className='bg-richblack-700 text-xl font-bold p-4 flex justify-between items-center rounded-t-lg'>
                <p>
                    {
                        add ? "Adding Section" :
                        edit ? "Editing Section" :
                        view && "Viewing Section"
                    }
                </p>
                <button
                    type='button'
                    className='text-3xl'
                    disabled={loading}
                    onClick={()=> setModalData(null)}
                >
                    <IoCloseOutline />
                </button>
            </div>

            {/* Section Details Form */}
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-between gap-y-8 items-stretch p-6'>

                {/* Section Heading */}
                <label>
                    <p>Heading</p>
                    <input
                        className="outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]"
                        type='text'
                        name='heading'
                        placeholder='Enter heading'
                        readOnly={loading || view}
                        {...register("heading",{
                                // required:{
                                //     value: true,
                                //     message: "Section Heading is Required"
                                // }
                            }
                        )}
                    />
                    {
                        errors.heading && 
                        <span className="mt-1 text-pink-200 text-[12px]">
                            {errors.heading.message}
                        </span>
                    }
                </label>                

                {/* Description */}
                <label>
                    <p>Description <sup>*</sup></p>
                    <textarea
                        className='outline-none mt-2 text-richblack-200 w-full min-h-[150px] p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                        placeholder='Enter Description'
                        readOnly={loading || view}
                        {...register("description",{
                                required:{
                                    value: true,
                                    message: "Description is Required"
                                }
                            }
                        )}
                    />
                    {
                        errors.description && 
                        <span className="mt-1 text-pink-200 text-[12px]">
                            {errors.description.message}
                        </span>
                    }
                </label>
                
                {/* Links */}
                <Requirements
                    label={<p>Links</p>}
                    edit={edit}
                    editData={modalData?.links ? modalData?.links : []}
                    name={"links"}
                    type={"text"}
                    placeholder={"Enter Link and Press Add"}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    require={false}
                />
                
                {/* Submit Button */}
                {
                    !view &&
                    <IconBtn
                        type={"submit"}
                        disabled={loading}
                        text={edit ? loading ? "Saving...." : "Save Changes" : "Add"}
                        customClasses={"self-end"}
                    />
                        
                }
            </form>

        </div>
    </div>
  )
}
