export const ACCOUNT_TYPE = {
    APPLICANT: "Applicant",
    RECRUITER: "Company",
    ADMIN: "Admin",
  }
  
  export const QUALIFICATION = {
    HIGH_SCHOOL:"High School Diploma",
    UNDERGRADUATE:"Undergraduate",
    GRADUATE:"Graduate",
    PROFESSIONAL:"Professional Qualifcation",POSTDOCTORAL:"Postdoctoral"
  }

  export const APPLICATION_STATUS = {
    ALL: "All",
    AWAIT: "Awaiting",
    SHORTLIST: "Shortlisted",
    CALL: "Call for Interview",
    INTERVIEW:"Interview Scheduled",
    HIRE: "Hired",
    REJECT: "Rejected"
  }

  export const WORK_STATUS = {
    COLLEGE: "College Student",
    FRESHER: "Fresher",
    EXPERIENCED: "Experienced",
    ALL:"All"
  }
  
  export const EMPLOYMENT_TYPE = {
    FULL: "Full Time",
    PART: "Part Time",
    INTERNSHIP: "Internship",
    CONTRACT: "Contract",
    TEMP_CONTRACT:"Temporary Contract"
  }
  
  export const WORK_TYPE = {
    OFFICE: "Work from Office",
    HOME: "Work from Home",
    HYBRID: "Hybrid",
    TEMP_REMOTE:"Temporary Remote"
  }

  export const SHIFT = {
    NIGHT: "Night",
    DAY: "Day"
  }

  export const BLOG_STATUS = {
    DRAFT: "Draft", 
    PUBLISHED: "Published",
  }