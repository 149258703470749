import { ACCOUNT_TYPE } from "../utils/constants";
export const sidebarLinks = [
  {
    id: 1,
    name: "My Profile",
    path: "/dashboard/recruiter/profile",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscAccount",
  },
  {
    id: 2,
    name: "My Profile",
    path: "/dashboard/applicant/profile",
    type: ACCOUNT_TYPE.APPLICANT,
    icon: "VscAccount",
  },
  {
    id: 4,
    name: "My Jobs",
    path: "/dashboard/recruiter/my-jobs",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscVm",
  },
  {
    id: 5,
    name: "Add Job",
    path: "/dashboard/recruiter/add-job",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscAdd",
  },
  {
    id: 6,
    name: "Schedule Walk-In",
    path: "/dashboard/recruiter/schedule-walk-in",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscAdd",
  },
  {
    id: 7,
    name: "Applications",
    path: "/dashboard/applicant/my-applications",
    type: ACCOUNT_TYPE.APPLICANT,
    icon: "VscMortarBoard",
  },
  {
    id: 8,
    name: "Applications",
    path: "/dashboard/recruiter/applications-received",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscMortarBoard",
  },
  {
    id: 9,
    name: "My Profile",
    path: "/dashboard/admin/profile",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscAccount",
  },
  {
    id: 10,
    name: "Create Admin",
    path: "/dashboard/admin/create-admin",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscAdd",
  },
  {
    id: 11,
    name: "Add Category",
    path: "/dashboard/admin/create-category",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscAdd",
  },
  {
    id: 12,
    name: "Categories",
    path: "/dashboard/admin/categories",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscMortarBoard",
  },
  {
    id: 13,
    name: "Recruiters",
    path: "/dashboard/admin/recruiters",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscMortarBoard",
  },
  {
    id: 14,
    name: "Applicants",
    path: "/dashboard/admin/applicants",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscMortarBoard",
  },
  {
    id: 15,
    name: "My Walk In Drives",
    path: "/dashboard/recruiter/my-walk-in-drives",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscVm",
  },
  {
    id: 16,
    name: "Registered drives",
    path: "/dashboard/applicant/my-registrations",
    type: ACCOUNT_TYPE.APPLICANT,
    icon: "VscVm",
  },
  {
    id: 17,
    name: "Create Blog",
    path: "/dashboard/recruiter/create-blog",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscAdd",
  },
  {
    id: 18,
    name: "My Blog",
    path: "/dashboard/recruiter/my-blogs",
    type: ACCOUNT_TYPE.RECRUITER,
    icon: "VscVm",
  }
];
