import { categories } from "../services/apis";

export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Jobs",
    // path: '/catalog',
  },
  {
    title: "Walk In Drives",
    path: '/walk-in-drives',
  },
  {
    title: "Blogs",
    path: '/blogs',
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
];


export const categoriesLink = [{
  "_id":"66de9d391d7c77f4b23ec089",
  "name": "Digital Marketing",
  "description": "Digital marketing includes roles focused on online brand promotion, audience engagement, data analysis, and campaign management, essential for driving growth in today's digital landscape.",
  "__v": 0,
  "jobs": [
    {
      "$oid": "6704e5b5c3b9aa5227357e61"
    },
    {
      "$oid": "67052220377ef00957f18664"
    }
  ]
},
{
  "_id": "66de9cfc1d7c77f4b23ec087",
  "name": "Software Engineering",
  "description": "A world of human computer interaction is software engineering",
  "__v": 0,
  "jobs": [
    {
      "$oid": "6704dd10bbb136c7ad53941a"
    },
    {
      "$oid": "67051ec7377ef00957f18631"
    },
    {
      "$oid": "67076e0a949cc0ab736d4c56"
    },
    {
      "$oid": "6707825f2bde31ed87cac9e3"
    },
    {
      "$oid": "670782622bde31ed87cac9f0"
    }
  ]
},
{
  "_id": "66ea94ddab06214cc0c14772",
  "name": "IT and Support",
  "description": "A world of human computer interaction is software engineering",
  "__v": 0,
  "jobs": [
    {
      "$oid": "6704e284c3b9aa5227357e48"
    }
  ]
},
{
  "_id":"66ea94baab06214cc0c14771",
  "name": "Sales and Management",
  "description": "A world of human computer interaction is software engineering",
  "__v": 0,
  "jobs": []
},
{
  "_id":"67178409bb70e4b909a9fb6b",
  "name": "Operations ",
  "description": "Efficient management of resources, processes, logistics, and organizational operations.",
  "jobs": [],
  "__v": 0
},
{
  "_id":"671f11f711388b42c7636394",
  "name": "Finance",
  "description": "The finance category involves the management, analysis, and strategic planning of monetary resources to optimize financial performance and decision-making.",
  "jobs": [],
  "__v": 0
}]