import React, { useEffect, useState } from 'react'
import { fetchAllBlogs } from '../services/operations/BlogAPI';
import Spinner from '../components/common/Spinner';
import { BlogCard } from '../components/core/Blog/BlogCard';

const Blogs = () => {

  const [blogs,setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    const fetchBlogs = async () => {
      setLoading(true);
      const data = await fetchAllBlogs();

      if(data){
        setBlogs(data);
      }

      setLoading(false);
    }
    fetchBlogs();
  },[])
  // console.log("Blogs",blogs)
  
  if(loading || !blogs)
    return (<Spinner/>)

  if(!loading && !blogs) {
    return <p>Error 404 Not Found</p>
  }
  
  return (
    <div className='w-11/12 max-w-maxContent mx-auto py-10 flex flex-col gap-y-16'>
      {
        (blogs && !blogs?.length) 
        ? <p className='w-full px-4 mt-8 text-yellow-200 font-medium text-2xl text-center'>
              No Jobs Found for the Selected Category 
          </p>
        : <div className='flex justify-start items-center'>
            {
              blogs?.map(bg => (
                <BlogCard key={bg?._id} bg={bg}/>
              )) 
            }
          </div>
      }
    </div>
  )
}

export default Blogs