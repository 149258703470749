// This will prevent authenticated users from accessing this route
import { useSelector } from "react-redux"
import { Navigate} from "react-router-dom"

function PrivateRoute({ children }) {

  const { token } = useSelector((state) => state.auth)
  // const { tokenExpiry } = useSelector((state) => state.auth)

  // if(tokenExpiry < Date.now())
  //   dispatch(logout(navigate));

  if (token !== null) {
    return children
  } else {
    return <Navigate to="/login" />
  }
}

export default PrivateRoute;