import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCourse, setEditCourse, setStep } from '../../../../../../slices/courseSlice';
import { useForm } from 'react-hook-form';
import IconBtn from '../../../../../common/IconBtn';
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineNavigateNext } from "react-icons/md";
// import { createSection, updateSection } from '../../../../../services/operations/CourseAPI';
import { NestedView } from './NestedView';
import toast from 'react-hot-toast';
import { SubSectionModal } from './SubSectionModal';

export const RenderCourseBuilderForm = () => {
  
  const dispatch = useDispatch();
  const {course} = useSelector(state => state.course);
  const {token} = useSelector(state => state.auth);
  const [addSection, setAddSection] = useState(null);
  const [editSection, setEditSection] = useState(null);
  const [viewSection, setViewSection] = useState(null);

  const {
    
    register,
    setValue,
    handleSubmit,
    formState:{errors}

  } = useForm();

  const gotoBack = ()=>{
    dispatch(setEditCourse(true));  
    dispatch(setStep(1));
  }

  const gotoNext = ()=>{
    if (course.sections.length === 0) {
      toast.error("Please add atleast one section")
      return
    }
    
    dispatch(setStep(3));
  }

  return (
    <div 
      className='flex flex-col p-5 my-10 bg-richblack-800 rounded-md border border-richblack-700'>
      
      <p className='text-2xl font-semibold'>Blog Builder</p>
      <div className='py-4'>
        <IconBtn
          text={"Create Section"}
          // disabled={loading}
          outline={true}
          // type={"submit"}
          onclick={()=> setAddSection(course._id)}
        >
          <IoAddCircleOutline size={20} className="text-yellow-50" />
        </IconBtn>
      </div>

      {
        course?.sections?.length > 0 &&
        <NestedView setEditSection={setEditSection} setViewSection={setViewSection}/> 
      }

      {/* Display SubSection Modal for add,edit and view subsection */}
      {
        addSection ? 
        <SubSectionModal
            modalData={addSection}
            setModalData={setAddSection}
            add={true}
        />
        : editSection ?
        <SubSectionModal
            modalData={editSection}
            setModalData={setEditSection}
            edit={true}
        />
        :viewSection &&
        <SubSectionModal
            modalData={viewSection}
            setModalData={setViewSection}
            view={true}
        />
      }

      {/* buttons */}
      <div className='self-end flex gap-x-3 flex-wrap-reverse'>
        <button
          className='bg-richblack-300 text-richblack-900 font-bold p-2 px-4 rounded-md'
          type='button'
          onClick={gotoBack}>
          Back
        </button>
        
        <button
          className='bg-yellow-50 flex gap-x-1 items-center text-richblack-900 font-bold p-2 px-4 rounded-md'
          type='button'
          onClick={gotoNext}>
          <span>Next</span>
          <span>
            <MdOutlineNavigateNext />
          </span>
        </button>
      </div>
    
    </div>
  )
}
